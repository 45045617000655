<div id="booking" class="header-area header-bg" style="background-image:url(assets/img/header-slider/01.png);">
  <div class="bg-img" style="background-image:url(assets/img/car.png);"></div>
  <div class="bg-img-02" style="background-image:url(assets/img/balloon/balloon.png);"></div>
  <div class="bg-img-03" style="background-image:url(assets/img/balloon/balloon-03.png);"></div>
  <div class="bg-img-04" style="background-image:url(assets/img/balloon/balloon-02.png);"></div>
  <div class="bg-img-05" style="background-image:url(assets/img/leaf/leaf-01.png);"></div>
  <div class="bg-img-06" style="background-image:url(assets/img/leaf/leaf-02.png);"></div>
  <div class="bg-img-07" style="background-image:url(assets/img/leaf/leaf-04.png);"></div>
  <div class="bg-img-08" style="background-image:url(assets/img/leaf/leaf-03.png);"></div>
  <div class="bg-img-09" style="background-image:url(assets/img/leaf/leaf-05.png);"></div>
  <div class="bg-img-10" style="background-image:url(assets/img/leaf/leaf-06.png);"></div>
  <div class="bg-img-11" style="background-image:url(assets/img/balloon/cercle.png);"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <div class="header-inner">
          <mat-horizontal-stepper #stepper>
            <mat-step [stepControl]="firstFormGroup" [editable]="editable">
                <div class="login-root">
                    <div class="box-root flex-flex flex-direction--column" style="flex-grow: 1;">

                        <div class="box-root flex-flex flex-direction--column" style="flex-grow: 1">

                            <div class="formbg-outer">
                                <div class="formbg">
                                    <div class="formbg-inner padding-horizontal--48">
                                        <form [formGroup]="firstFormGroup" id="stripe-login">
                                            <span class="padding-bottom--15"
                                                style="display: block; font-size: 20px; color: #000;">Choose
                                                your Jouney</span>
                                            <mat-radio-group style="
                                          
                                            display: inline-block;
                                        " aria-label="Select an option" formControlName="tripType">
                                                <mat-radio-button value="One Way" style="margin-right: 10px;">
                                                    One Way
                                                </mat-radio-button>
                                                <mat-radio-button value="Round Trip">Round Trip
                                                </mat-radio-button>
                                            </mat-radio-group>
                                            <div class="grid--50-50">
                                                <div class="field padding-bottom--24">
                                                    <label for="fullName">Name</label>
                                                    <input placeholder="Full name" formControlName="name">
                                                    <mat-error
                                                        *ngIf="submitted && contactValidation.name.errors">
                                                        Name is <strong>required</strong>
                                                    </mat-error>
                                                </div>
                                                <div class="field padding-bottom--24">
                                                    <label for="fullName">Mobile Number</label>
                                                    <input placeholder="Mobile number" formControlName="phone">
                                                    <mat-error
                                                        *ngIf="submitted && contactValidation.phone?.errors?.required">
                                                        Mobile number is <strong>required</strong>
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="submitted && contactValidation.phone?.errors?.pattern">
                                                        Enter valid <strong> Mobile number </strong>
                                                    </mat-error>
                                                </div>
                                            </div>

                                            <div class="field padding-bottom--24">
                                                <div class="grid--50-50">
                                                    <label for="fullName">Email Id(optional)
                                                    </label>
                                                </div>
                                                <input matInput placeholder="Email id" formControlName="email">

                                                <mat-error
                                                    *ngIf="submitted && contactValidation.email?.errors?.pattern">
                                                    Enter valid <strong>Email ID</strong>
                                                </mat-error>
                                            </div>
                                            <div class="grid--50-50">
                                                <div class="field padding-bottom--24">
                                                    <label for="fullName">Pickup Address</label>

                                                    <input placeholder="Pickup address" #origin>
                                                </div>
                                                <div class="field padding-bottom--24">
                                                    <label for="fullName">Drop Address</label>
                                                    <input placeholder="Drop address" #destination>
                                                </div>
                                            </div>
                                            <agm-map class="hide" [latitude]="latitude" [longitude]="longitude"
                                                [zoom]="5">
                                                <ng-container *ngIf="showDirection">
                                                    <agm-direction [origin]="pickupPlace"
                                                        [destination]="dropPlace"
                                                        (onResponse)="onResponses($event)">
                                                    </agm-direction>
                                                </ng-container>
                                            </agm-map>
                                            <div class="grid--50-50">
                                                <div class="field padding-bottom--24">
                                                    <label for="fullName">Pickup Date</label>

                                                    <input placeholder="Pickup date"
                                                        formControlName="pickupDate" [min]="minDate"
                                                        (focus)="picker.open()" [matDatepicker]="picker"
                                                        readonly>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                    <mat-error
                                                        *ngIf="submitted && contactValidation.pickupDate?.errors?.required">
                                                        Pickup Date is <strong>required</strong>
                                                    </mat-error>
                                                </div>
                                                <div class="field padding-bottom--24">
                                                    <label for="fullName">Pickup Time</label>

                                                    <mat-select class="form-control time-select" formControlName="pickupTime" placeholder="Time">
                                                      <mat-option *ngFor="let time of times" [value]="time">
                                                        {{time}}
                                                      </mat-option>
                                                    </mat-select>
                                                    <mat-error
                                                        *ngIf="submitted && contactValidation.pickupTime?.errors?.required">
                                                        Pickup Time is <strong>required</strong>
                                                    </mat-error>
                                                </div>
                                            </div>


                                            <div *ngIf="contactValidation.tripType.value === 'Round Trip'"
                                                class="field padding-bottom--24">
                                                <div class="grid--50-50">
                                                    <label for="fullName">Return Date</label>
                                                </div>
                                                <input [min]="contactValidation.pickupDate.value"
                                                    (focus)="picker1.open()" [matDatepicker]="picker1" matInput
                                                    placeholder="Retun date" formControlName="returnDate"
                                                    readonly>
                                                <mat-datepicker #picker1></mat-datepicker>
                                            </div>
                                            <div class="clear"></div>
                                            <div class="carbox">

                                                <div *ngFor="let car of cars" class="col">
                                                    <div class="cars-item" (click)="selectedCarType(car)">
                                                        <label>{{ contactValidation.tripType.value
                                                            !== 'Round Trip' ? car.oneway :
                                                            car.roundtrip}} ₹
                                                            / Km</label>
                                                        <img *ngIf="car.carType !== selectedCarName"
                                                            src="{{car.image}}">
                                                        <img *ngIf="car.carType === selectedCarName"
                                                            src="{{car.activeImage}}">
                                                        <label>{{car.carType}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="field padding-bottom--24">
                                                <button class="booking" (click)="requestAride()">Get
                                                    Estimate</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step>

                <div *ngIf="show" class="estimate-wrap">
                    <p>Trip estimation for {{bookingDetails.originCityName}} to
                        {{bookingDetails.destinationCityName}}</p>
                    <strong style="
                    display: block;
                    font-size: 32px;
                    color: #fff;
                    margin: 20px 0;
                ">
                        <span>Fare</span> {{bookingDetails.tripEstimation | currency:'INR' }}
                    </strong>
                    <b>Total Distance: </b>{{bookingDetails.distance}} KM <br>
                    <b>Total Duration:</b> {{bookingDetails.totalDuration}} <br>
                    <b>Selected Car:</b> {{bookingDetails.carType}} <br>
                    <b>Driver Batta:</b> ₹{{bookingDetails.driverBatta}} <span *ngIf="days"> for
                        {{this.days}} Days </span> <br>

                    <em style="
                    margin: 20px 0;
                    display: inline-block;
                ">Note: Above estimation is exclusive of Toll Gate and State Permit Etc</em>
                    <div class="col-12 text-center mt-3 mb-3">
                        <button (click)="confirm()" mat-raised-button color="primary">Confirm
                            Booking</button>
                    </div>
                </div>

            </mat-step>
            <mat-step>
                <div class="success">
                    <div style="text-align: center;" *ngIf="bookingDetails">
                        <h4>Ride Booked Successfully</h4>
                        <img src="https://images.ctfassets.net/509kpi6dw56l/4zLD6wiohRdGrrQtWGD0KO/49149fa4ce096618802c2c2b0fee57a1/success.gif?h=250"
                            alt="" srcset="">
                        <p>
                            Thanks for Choosing {{settings.siteName}}, reservation details have been
                            sent
                            to your email id & phone.
                        </p>
                        <div style="text-align: center;">
                            <button (click)="home()" mat-raised-button color="primary">Home</button>
                        </div>
                    </div>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
        </div>
        <!-- //.header inner -->
      </div>
    </div>
  </div>
</div>
<div id="packages" class="header-bottom-area padding-top-110">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4">
        <div class="section-title desktop-center margin-bottom-60">
          <span class="sub-title">CHOOSE FROM OUR FLEET</span>
          <h3 class="title">OUR TAXI
          </h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngFor="let car of cars" class="col-lg-3 col-md-6">
        <div class="content">
          <h4 class="title">{{car.carType | uppercase}}</h4>
          <p class="title">{{car.availableCars}}</p>
        </div>
        <div class="single-package-item water-effect bg-image"
          style="background-image: url({{car.tariffImage}});">
        </div>
        <h4>One Way - {{car.oneway | currency: 'INR'}} per km </h4>
        <h4>Round trip - {{car.roundtrip | currency: 'INR'}} per km </h4>
      </div>

      <div class="col-md-12">
                
        <div class="col-lg-12 mt-4">
          <div class="heading tp_one  icon_dark">
              <h6>Terms and conditions</h6>
              <h1>Points before ur travel
              </h1>
          </div>
          <div class="about_content">
              <h4> Any Sedan</h4>
              <p class="description">

                  Swift Dzire Toyota Etios Tata Zest Hyundai Xcent Nissan sunny anyone will be assigned

              </p>

              <h4>Any SUV
              </h4>

              <p class="description">Innova Xylo Ertiga any one will be assigned

              </p>

              <ul>
                  <li>Maximum 4 passenger capacity for Sedan</li>
                  <li>Maximum 7 passenger capacity for SUV</li>

                  <li>Minimum 130km coverage for one way trip</li>
                  <li> Minimum 250km coverage for round trip.</li>
                  <li>One day Round trip close by 11pm at ur pickup </li>
                  <li>Waiting charges Rs.150 per hour in between ur trip.</li>
                  <li>Food break, tea break, & rest room no waiting chrgs.</li>
                  <li>Cancellation charges Rs.300 after driver arrived.</li>
                  <li>Luggage load in carrier Rs.300 if Mention in QT.</li>
                  <li>Note down starting and ending km is must.</li>
                  <li> Permit charges and Driver Bata is same as quoted.</li>
                  <li>Toll charges will be paid thru fastag by Driver.</li>
                  <li>
                      Payment  transfer by online or by cash.
                  </li>
                  <li>
                      EOT calculated running km, Toll by fastag & rest all.
                  </li>
                  <li> Toll fees, interstate Permit, Hill charges pet charges waiting charges (if any) are
                      extra
                  </li>
                  <li>For Bangalore minimum 300km per day in Round trip
                  </li>
                  <li>1 day means 1 calendar day(from midnight 12 to next midnight 12)
                  </li>
              </ul>

          </div>
      </div>

      </div>


    </div>
  </div>
</div>
<!-- Why Choose Us -->
<div id="why" class="choose-us-area bg-image padding-top-90" style="background-image: url(assets/img/choose/bg.png);">
  <div class="bg-img" style="background-image: url(assets/img/choose/01.png);"></div>
  <div class="bg-img-02" style="background-image: url(assets/img/leaf/leaf-01.png);"></div>
  <div class="bg-img-03" style="background-image: url(assets/img/leaf/leaf-02.png);"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <div class="section-title padding-bottom-25">
          <span class="sub-title">Why Choose Us</span>
          <h4 class="title">We are <span>best cab service provider</span> with <span>100% satisfaction</span> services
          </h4>
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <div class="section-title padding-bottom-25">
          <span class="sub-title">{{settings.siteName}}</span>
          <h4 class="title">A Little About Us </h4>
          <p>
            {{settings.siteName}} is one of the leading cab service in Chennai, provides its services between various locations
            across Tamil Nadu at cheapest price.
          </p>
          <p>We're, One of the best and trusted outstation journey for a cost saving and safe journey in and around
            Bangalore, Chennai, across Tamil Nadu, Pondicherry, Kerala, Andhra.
          </p>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="destination-single-item margin-bottom-30">
              <div class="icon">
                <i class="flaticon-hotel"></i>
              </div>
              <div class="content">
                <h4 class="title">
                  Professional Drivers
                </h4>
                <p>While our expert drivers believe in safe driving, Our Driver understands customer requirements and
                  ensure their safety

                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="destination-single-item margin-bottom-30">
              <div class="icon style-01">
                <i class="flaticon-notification"></i>
              </div>
              <div class="content">
                <h4 class="title">
                  <a href="">Reasonable Price
                  </a>
                </h4>
                <p>We offer droptaxi service at very low cost compared to other droptaxi services

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="call-to-action-area padding-top-80 bg-image">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="call-to-action-inner">
            <div class="line-area">
              <span class="line-one"></span>
              <span class="line-two"></span>
              <span class="line-three"></span>
            </div>
            <span class="bubble"></span>
            <div class="action-bg-img" style="background-image: url(assets/img/call-to-action/animation/dot.png);">
            </div>
            <div class="action-bg-img-02"
              style="background-image: url(assets/img/call-to-action/animation/cricle.png);"></div>
            <h2 class="title">{{settings.siteName}}

            </h2>
            <p class="subtitle">Keeping You And Your Family Safe!
            </p>
            <div class="btn-wrapper desktop-center">
              <a href="#booking" class="boxed-btn">BOOK NOW</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Destination Us -->
<div class="destination-area padding-bottom-90 padding-top-115">
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <div class="destination-img bg-image" style="background-image: url(assets/img/bg/01.png);"></div>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <div class="section-title padding-bottom-25">
          <span class="sub-title">Select Destination</span>
          <h4 class="title">Get Booking In 3 Steps</h4>
          <p>Always there when you need a taxi... We are the experts to handle crisis situation travel, even during this
            pandemic situation 100% sanitized cars with healthy drivers 😀
          </p>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="destination-single-item margin-bottom-30">
              <div class="icon">
                <i class="flaticon-hotel"></i>
              </div>
              <div class="content">
                <h4 class="title">
                  <a href="">Make Reservation
                  </a>
                </h4>
                <p>A more convenient way to make your cab reservation just click on the call icon to reach us at
                  {{settings.phoneNo}} or whatsapp icon to chat with us at {{settings.whatsapp}}.

                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="destination-single-item margin-bottom-30">
              <div class="icon style-01">
                <i class="flaticon-notification"></i>
              </div>
              <div class="content">
                <h4 class="title">
                  <a href="">Enjoy Your Ride</a>
                </h4>
                <p>We have clean, sanitized and comfortable cars. All you need to do is sit back tight, relax and enjoy
                  the comforts of your ride.

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Book A Trip-->
<div class="trip-area padding-bottom-120">
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <div class="section-title padding-bottom-25">
          <span class="sub-title">Book A Trip</span>
          <h4 class="title">Then <span>book your trip</span> from our exclusive <span>offer ₹</span></h4>
          <p>There is always a tariff for taxis with us that suits your budget at our {{settings.siteName}} . We have the best
            cabs for reasonable and affordable prices. Sure there is one for you.

          </p>
        </div>
        <div class="content">
          <h3 class="sub-title">Airport Cab Service</h3>
          <p>Book our airport taxi service for a comfortable and timely commute. Get picked up or dropped to the airport
            by our expert chauffeurs and never miss a flight or be late for a meeting again.

          </p>
          <h3 class="sub-title">Outstation Cab Service</h3>
          <p>We offers one way taxi service across all major towns and cities in Tamilnadu. From now on, if you are not
            sure about your return journey and primarily looking to travel on one way journey, you can hire one way taxi
            services at Daynight Drop Taxi and save money.

          </p>
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <div class="trip-img bg-image" style="background-image: url(assets/img/bg/02.png);"></div>
      </div>
    </div>
  </div>
</div>