<!-- footer area start -->
<footer class="footer-area">
    <div class="footer-top bg-black bg-image padding-top-85 padding-bottom-50">
        <div class="container">
            <div class="footer-top-border padding-bottom-30">
                <div class="row">
                    <div class="col-lg-3 col-md-4">
                        <div class="footer-widget widget">
                            <div class="about_us_widget">
                               
                                <p>Plan your outstation journey anywhere across India with choice of cars from economy
                                    hatchbacks to sedan, from premium sedans to SUVs.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 offset-lg-1 col-md-8">
                        <div class="footer-widget widget widget_subscribe">
                            <div class="header-content">
                                <div class="single-contact-item-02">
                                    <div class="icon">
                                        <i class="flaticon-call"></i>
                                    </div>
                                    <div class="content">
                                        <p class="details"><a href="tel:{{settings.whatsapp}}">{{settings.whatsapp}}</a></p>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="single-contact-item-02 subscribe-form">
                                <div class="icon">
                                    <i class="flaticon-call"></i>
                                </div>
                                <div class="content">
                                    <p class="details"><a href="tel:+919142883333">+91 9142 883 333</a></p>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="footer-widget widget widget_nav_menu">
                        <h4 class="widget-title">Airport Service </h4>
                        <ul>
                            <li><a href="#">Chennai Airport Taxi Service</a></li>
                            <li><a href="#">Bangalore Airport Taxi Service
                                </a></li>
                            <li><a href="#">Coimbatore Airport Taxi Service
                                </a></li>
                            <li><a href="#">Trichy Airport Taxi Service
                                </a></li>
                            <li><a href="#" target="_blank" rel="noopener noreferrer">Madurai Airport Taxi Service
                                </a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="footer-widget widget widget_nav_menu">
                        <h4 class="widget-title">One Way Out Station Trips
                        </h4>
                        <ul>
                            <li><a href="#">One Way Taxi Kodikanal to Madurai
                                </a></li>
                            <li><a href="#">One Way Taxi Palani to Kodikanal
                                </a></li>
                            <li><a href="#">One Way Taxi Madurai to Rameshwaram
                                </a></li>
                            <li><a href="#">One Way Taxi Thriuvannamalai to Chennai
                                </a></li>
                            <li><a href="#">One Way Taxi Madurai to Trichy
                                </a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="footer-widget widget widget_nav_menu">
                        <h4 class="widget-title">One Way Out Station Trips
                        </h4>
                        <ul>
                            <li><a href="#">One Way Taxi Chennai to Bangalore

                                </a></li>
                            <li><a href="#">One Way Taxi Chennai to Pondicherry
                                </a></li>
                            <li><a href="#">One Way Taxi Chennai to Madurai
                                </a></li>
                            <li><a href="#">One Way Taxi Chennai to Trichy
                                </a></li>
                            <li><a href="#">One Way Taxi Chennai to Coimbatore
                                </a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="footer-widget widget widget_nav_menu">
                        <h4 class="widget-title">One Way Out Station Trips
                        </h4>
                        <ul>
                            <li><a href="#">One Way Taxi Chennai to Thanjavur
                                </a></li>
                            <li><a href="#">One Way Taxi Chennai to Cuddalore
                                </a></li>
                            <li><a href="#">One Way Taxi Chennai to Kanyakumari
                                </a></li>
                            <li><a href="#">One Way Taxi Chennai to Kumbakonam
                                </a></li>
                            <li><a href="#">One Way Taxi Coimbatore to Kodaikanal
                                </a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="copy">
                <a href="http://www.calvincareinfotech.com" target="_blank">Developed By Calvin Care Website
                    Designing Company</a>
            </div>
        </div>
    </div>

</footer>
<!-- footer area end -->