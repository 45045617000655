import { Component, OnInit } from '@angular/core';
import { SETTINGS } from 'src/app/setting';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  settings = SETTINGS;
  
  constructor() { }

  ngOnInit(): void {
  }

}
