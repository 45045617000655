<div class="breadcrumb-area  margin-bottom-120">
  <div class="shape"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="breadcrumb-inner">
          <div class="icon">
            <i class="flaticon-stars"></i>
            <p>Contact</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="idea" class="build-area">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="section-title desktop-center margin-bottom-55">
          <h3 class="title social-title">Everything you’ll need. Nothing you wont </h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="single-everything-item">
          <div class="icon">
            <i class="flaticon-pin"></i>
          </div>
          <div class="content">
            <h3 class="title">Visit Us Anytime
            </h3>
            <p>Chennai, Coimbatore </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="single-everything-item">
          <div class="icon style-01">
            <i class="flaticon-mail"></i>
          </div>
          <div class="content">
            <h3 class="title">Send a Email
            </h3>
            <p><a href="mailto:{{settings.enquiryEmail}}">{{settings.enquiryEmail}}</a></p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="single-everything-item">
          <div class="icon style-02">
            <i class="flaticon-call"></i>
          </div>
          <div class="content">
            <h3 class="title">Call Center
            </h3>
            <p><a href="tel:{{settings.whatsapp}}">{{settings.whatsapp}}</a></p>
            <!-- <p><a href="tel:+919142883333">+91 9142 883 333</a></p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br>
<br>

<div class="create-content-area padding-bottom-110">
  <div class="container">
    <div class="row">
      <div class="col-xl-4 col-lg-6">
        <div class="create-content-wrap">
          <div class="section-title startup padding-bottom-25">
            <h4 class="title">How Can We Help You?
            </h4>
            <p>Plan your outstation journey anywhere across India with choice of cars from economy hatchbacks to sedan,
              from premium sedans to SUVs.
            </p>
          </div>
        </div>
      </div>
      <div class="col-xl-6 offset-xl-2 col-lg-6">
        <img src="assets/img/register/01.png" />
      </div>
    </div>
  </div>
</div>