<div class="breadcrumb-area  margin-bottom-120">
  <div class="shape"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="breadcrumb-inner">
          <div class="icon">
            <i class="flaticon-stars"></i>
            <p>About Us</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="why" class="unique-project-area padding-top-70">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="section-title brand desktop-center margin-bottom-55">
          <h3 class="title">Welcome To {{settings.siteName}}
          </h3>
          <p>{{settings.siteName}} is one of the leading cab service in Chennai, provides its services between various locations
            across Tamil Nadu at cheapest price. We're, One of the best and trusted outstation journey for a cost saving
            and safe journey in and around Bangalore, Chennai, across Tamil Nadu, Pondicherry, Kerala, Andhra.

          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="unique-content-area">
          <div class="section-title brand">
            <h4 class="title">The quickest, cleanest Cabs
              In Chennai.</h4>
            <p>{{settings.siteName}} is based in Chennai and our service details are provided as below. we provide return
              trip also along with one way if needed. We are the leading outdoor taxi service in Chennai by providing
              best service at the lowest fare.For any queries, you may contact our customer service.

            </p>
            <ul class="content">
              <li><i class="flaticon-save-money"></i><strong> We hire only Professional and Expert Drivers
                </strong></li>
              <li><i class="flaticon-save-money"></i><strong> Reasonable Price
                </strong></li>
              <li><i class="flaticon-save-money"></i><strong> On Time Service

                </strong></li>
              <li><i class="flaticon-save-money"></i><strong> Trusted by 5000 Clients

                </strong></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="shape" style="background-image: url(assets/img/unique-project/bg.png);">
          <div class="shape-02"></div>
          <img src="assets/img/unique-project/01.png" alt="">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="get-in-touch-area padding-bottom-100 padding-top-115">
  <div class="bg-image" style="background-image: url(assets/img/get-in-touch/01.png);"></div>
  <div class="bg-image-02" style="background-image: url(assets/img/get-in-touch/02.png);"></div>
  <div class="bg-image-03" style="background-image: url(assets/img/get-in-touch/22.png);"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-4 offset-lg-3">
        <div class="section-title brand white">
          <p>Get In Touch</p>
          <div class="title">{{settings.enquiryEmail}} <br> {{settings.whatsapp}}</div>
        </div>
      </div>
    </div>
  </div>
</div>