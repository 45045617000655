<!-- preloader area start -->
<div *ngIf="show" class="preloader" id="preloader">
    <div class="preloader-inner">
        <div></div>
        <hr />
    </div>
</div>
<!-- preloader area end -->
<div class="header-style-01">
    <!-- support bar area end -->
    <nav class="navbar navbar-area navbar-expand-lg nav-style-02">
        <div class="container nav-container political-02">
            <div class="responsive-mobile-menu">
                <div class="logo-wrapper">
                    <a href="index.html" class="logo">
                        <img src="assets/logo.png" alt="">
                    </a>
                </div>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#bizcoxx_main_menu"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            <div class="collapse navbar-collapse" id="bizcoxx_main_menu">
                <ul class="navbar-nav">
                    <li><a routerLink="/">Home</a></li>
                    <li><a routerLink="/about">About</a></li>
                    <li><a routerLink="/tariff">Tariff</a></li>
                    <li><a routerLink="/contact">Contact</a></li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- navbar area end -->
</div>