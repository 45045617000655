import { Component } from '@angular/core';
import { SETTINGS } from './setting';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  settings = SETTINGS;
}
