<div class="breadcrumb-area  margin-bottom-120">
  <div class="shape"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="breadcrumb-inner">
          <div class="icon">
            <i class="flaticon-stars"></i>
            <p>Tariff</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div id="why" class="unique-project-area">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="unique-content-area">
          <div class="section-title brand">
            <div class="tg-bookinginfo">
              <h2>Tariff Chart</h2><br>
              <div class="padding_space">
                <div class="respo">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Package</th>
                        <th>One Way / KM</th>
                        <th>Round Trip / KM</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let car of cars"> 
                        <td>{{car.carType | uppercase}} <br> {{car.availableCars}} </td>
                        <td>{{car['one-way'] | currency: 'INR' }}</td>
                        <td>{{car['round-trip'] | currency: 'INR' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="tg-sectionspace samplei col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div id="tg-content" class="tg-content">
                <div class="tg-faqs">
                  <div class="tg-item">
                    <div class="tg-featuretitle"><br>
                      <h2>Rates Given Above</h2><br>
                    </div>
                    <div class="tg-description">
                      <p>Toll fees,Inter-State Permit charges (if any) are extra.</p>
                      <p>Drop Trips-Driver Bata Rs.300.- Waiting Charges Rs.100 per hour.
                      </p>
                      <p>Drop Trips-Minimum running must be 130kms per day.</p>
                      <p>Round Trips-Driver Bata Rs.300/- per day.</p>
                      <p>Round Trips-Minimum running must be 250kms per day.For Bengaluru it
                        is minimum 300kms per day.</p>
                      <p>Hill station charges - Rs.300</p>
                      <p>1 day means 1 calender day (from midnight 12 to Next Midnight 12)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<br>
<br>
<br>